import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const AdminInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);

  const currentUrl = router.routerState.snapshot.url;
  let pathName;
  // ON exclut les assets de la recherche
  if (!req.url.includes('/assets')) {
    const url = new URL(req.url);
    pathName = url.pathname;
  }
  // on admin route or calling '/me' from auth.guard
  const isAdminRoute =
    currentUrl.startsWith('admin') ||
    pathName === '/me';

  if (!isAdminRoute) return next(req);

  const headers = req.headers.delete('x-auth-token');

  const clonedReq = req.clone({ headers });

  return next(clonedReq);
};
