import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

export const xAuthTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const currentUrl = router.routerState.snapshot.url;
  // ON exclut les assets de la recherche
  let pathName;
  if (!req.url.includes('/assets')) {
    const url = new URL(req.url);
    pathName = url.pathname;
  }
  // on candidate route or calling '/custom-user-exam-session/check-authentication' from auth.guard
  const isCandidateRoute =
    currentUrl.includes('candidate') || pathName === '/custom-user-exam-session/check-authentication';

  if (!isCandidateRoute) return next(req);

  const isToken = authService.checkCandidateToken();

  if (!isToken) return next(req);

  const token = authService.getCandidateToken();
  const headers = req.headers
    .delete('Authorization')
    .set(authService.CANDIDATE_COOKIE, token);
  const clonedReq = req.clone({ headers });

  return next(clonedReq);
};
