import { HttpInterceptorFn } from '@angular/common/http';
import { TENANT_KEY } from '@app/app.component';

export const tenantInterceptor: HttpInterceptorFn = (req, next) => {
  const tenant = localStorage.getItem(TENANT_KEY);

  if (tenant != null) {
    const headers = req.headers.set('x-tenant', tenant);
    return next(req.clone({ headers }));
  }
  return next(req);
};
