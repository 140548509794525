import { environement } from "@app/environments/environment";
import { KeycloakService } from "keycloak-angular";

export function initializeKeycloak(keycloak: KeycloakService) {
  return (): Promise<boolean> =>
    keycloak.init({
      config: {
        url: environement.KEYCLOAK_URL,
        realm: environement.KEYCLOAK_REALM,
        clientId: environement.KEYCLOAK_CLIENT_ID,
      },
      initOptions: {
        // onLoad: 'login-required', We don't want to force the candidate to login with keycloak
        checkLoginIframe: false
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
    });
}
